<template>
    <v-dialog
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="500px"
        min-width="500px"
        max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text">
                <template v-if="type === 'NSV'">
                    {{ $t('message.updateBundlesCost') }}
                </template>
                <template v-if="type === 'SV'">
                    {{ $t('message.updatePalletsCost') }}
                </template>
            </v-card-title>
            <v-card-text>
                <v-overlay
                    :value="loading.values"
                    absolute
                    opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                color="primary"
                                indeterminate
                                size="40"
                                width="6"
                            ></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="massBundleForm" v-model="validForm">
                    <template v-if="type === 'NSV'">
                        <v-layout row pt-3>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center" v-if="type === 'NSV'">{{ $t('message.buyingPrice') + ' (OC)' }}</v-flex>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center" v-if="type === 'SV'">{{ $t('message.gradingAdjustedCost') + ' (OC)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :rules="[...validationRules.required, ...validationRules.amount, ...validationRules.minimumZero]"
                                            :value="Stock__buying_price_original"
                                            autocomplete="off"
                                            class="force-text-left"
                                            clearable
                                            dense
                                            hide-details="auto"
                                            solo
                                            @change="updateCostField('Stock__buying_price_original', $event)"
                                        />
                                        <span class="font-sm ml-3 min-width-70">{{ stockOrderCurrencyCode + '/' + uofm }}</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.importDuty') + ' (OC)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                            :value="Stock__import_duty_original"
                                            autocomplete="off"
                                            class="force-text-left"
                                            clearable
                                            dense
                                            hide-details="auto"
                                            solo
                                            @change="updateCostField('Stock__import_duty_original', $event)"
                                        />
                                        <span class="font-sm ml-3 min-width-70">{{ stockOrderCurrencyCode + '/' + uofm }}</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.freight') + ' (OC)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :rules="[...validationRules.required, ...validationRules.amount, ...validationRules.minimumZero]"
                                            :value="Stock__freight_cost_original"
                                            autocomplete="off"
                                            class="force-text-left"
                                            clearable
                                            dense
                                            hide-details="auto"
                                            solo
                                            @change="updateCostField('Stock__freight_cost_original', $event)"
                                        />
                                        <span class="font-sm ml-3 min-width-70">{{ stockOrderCurrencyCode + '/' + uofm }}</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.warehouseCost') + ' (OC)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :rules="[...validationRules.required, ...validationRules.amount, ...validationRules.minimumZero]"
                                            :value="Stock__wh_cost_original"
                                            autocomplete="off"
                                            class="force-text-left"
                                            clearable
                                            dense
                                            hide-details="auto"
                                            solo
                                            @change="updateCostField('Stock__wh_cost_original', $event)"
                                        />
                                        <span class="font-sm ml-3 min-width-70">{{ stockOrderCurrencyCode + '/' + uofm }}</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row pb-3>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.landedWarehouseCost') + ' (OC)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :value="Stock__landed_wh_cost_original"
                                            autocomplete="off"
                                            class="force-text-left bg-lightgrey"
                                            dense
                                            hide-details="auto"
                                            readonly
                                            solo
                                        />
                                        <span class="font-sm ml-3 min-width-70">{{ stockOrderCurrencyCode + '/' + uofm }}</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <hr>
                        <v-layout row pt-2>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.exchangeRate') + ' (Payment Date)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :value="Stock__ex_rate_stockorder"
                                            autocomplete="off"
                                            class="force-text-left bg-lightgrey"
                                            dense
                                            hide-details="auto"
                                            readonly
                                            solo
                                        />
                                        <span class="font-sm ml-3 min-width-70">{{ localStockCurrencyCode + ':' + stockOrderCurrencyCode }}</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.buyingPriceLocal') }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :value="Stock__buying_price_local"
                                            autocomplete="off"
                                            class="force-text-left bg-lightgrey"
                                            dense
                                            hide-details="auto"
                                            readonly
                                            solo
                                        />
                                        <span class="font-sm ml-3 min-width-70">{{ localStockCurrencyCode + '/' + uofm }}</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row pb-3>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.landedWarehouseCost') + ' (LC)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :value="Stock__landed_wh_cost_local"
                                            autocomplete="off"
                                            class="force-text-left bg-lightgrey"
                                            dense
                                            hide-details="auto"
                                            readonly
                                            solo
                                        />
                                        <span class="font-sm ml-3 min-width-70">{{ localStockCurrencyCode + '/' + uofm }}</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <hr>
                        <v-layout row pt-2>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.currentBuyingPrice') + ' (OC)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :rules="[...validationRules.required, ...validationRules.amount, ...validationRules.minimumZero]"
                                            :value="Stock__current_buying_price_original"
                                            autocomplete="off"
                                            class="force-text-left"
                                            clearable
                                            dense
                                            hide-details="auto"
                                            solo
                                            @change="updateCostField('Stock__current_buying_price_original', $event)"
                                        />
                                        <span class="font-sm ml-3 min-width-70">{{ stockOrderCurrencyCode + '/' + uofm }}</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.exchangeRate') + ' (Current)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :rules="[...validationRules.required, ...validationRules.amount, ...validationRules.minimumZero]"
                                            :value="Stock__current_ex_rate"
                                            autocomplete="off"
                                            class="force-text-left"
                                            clearable
                                            dense
                                            hide-details="auto"
                                            solo
                                            @change="updateCostField('Stock__current_ex_rate', $event)"
                                        />
                                        <span class="font-sm ml-3 min-width-70">{{ localStockCurrencyCode + ':' + stockOrderCurrencyCode }}</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row pb-3>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.currentBuyingPrice') + ' (LC)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :value="Stock__current_buying_price_local"
                                            autocomplete="off"
                                            class="force-text-left bg-lightgrey"
                                            dense
                                            hide-details="auto"
                                            readonly
                                            solo
                                        />
                                        <span class="font-sm ml-3 min-width-70">{{ localStockCurrencyCode + '/' + uofm }}</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <hr>
                        <v-layout row pt-2>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.bookValue') + ' %' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                            :value="Stock__book_value_pct"
                                            autocomplete="off"
                                            class="force-text-left"
                                            dense
                                            hide-details="auto"
                                            solo
                                            @change="updateCostField('Stock__book_value_pct', $event)"
                                        />
                                        <span class="font-sm ml-3 min-width-70">%</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.bookValue')  + ' (OC)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                            :value="Stock__book_value_original"
                                            autocomplete="off"
                                            class="force-text-left bg-lightgrey"
                                            dense
                                            hide-details="auto"
                                            readonly
                                            solo
                                        />
                                        <span class="font-sm ml-3 min-width-70">{{ stockOrderCurrencyCode + '/' + uofm }}</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row pb-3>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.bookValue') + ' (LC)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                            :value="Stock__book_value_local"
                                            autocomplete="off"
                                            class="force-text-left bg-lightgrey"
                                            dense
                                            hide-details="auto"
                                            readonly
                                            solo
                                        />
                                        <span class="font-sm ml-3 min-width-70">{{ localStockCurrencyCode + '/' + uofm }}</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <hr>
                        <v-layout row pt-2>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.adjustedValue') + ' %' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                            :value="Stock__adjusted_value_pct"
                                            autocomplete="off"
                                            class="force-text-left"
                                            dense
                                            hide-details="auto"
                                            solo
                                            @change="updateCostField('Stock__adjusted_value_pct', $event)"
                                        />
                                        <div class="font-sm ml-3 min-width-70">%</div>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.adjustedValue')  + ' (OC)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                            :value="Stock__adjusted_value_original"
                                            autocomplete="off"
                                            class="force-text-left bg-lightgrey"
                                            dense
                                            hide-details="auto"
                                            readonly
                                            solo
                                        />
                                        <div class="font-sm ml-3 min-width-70">{{ stockOrderCurrencyCode + '/' + uofm }}</div>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.adjustedValue') + ' (LC)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                            :value="Stock__adjusted_value_local"
                                            autocomplete="off"
                                            class="force-text-left bg-lightgrey"
                                            dense
                                            hide-details="auto"
                                            readonly
                                            solo
                                        />
                                        <div class="font-sm ml-3 min-width-70">{{ localStockCurrencyCode + '/' + uofm }}</div>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                    </template>
                    <template v-if="type === 'SV'">
                        <v-layout row pt-3>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.gradingAdjustedCost') + ' (OC)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :rules="[...validationRules.required, ...validationRules.amount, ...validationRules.minimumZero]"
                                            :value="Stock__buying_price_original"
                                            autocomplete="off"
                                            class="force-text-left"
                                            clearable
                                            dense
                                            hide-details="auto"
                                            solo
                                            @change="updateCostField('Stock__buying_price_original', $event)"
                                        />
                                        <span class="font-sm ml-3 min-width-70">{{ stockOrderCurrencyCode + '/' + uofm }}</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.importDuty') + ' (OC)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                            :value="Stock__import_duty_original"
                                            autocomplete="off"
                                            class="force-text-left"
                                            clearable
                                            dense
                                            hide-details="auto"
                                            solo
                                            @change="updateCostField('Stock__import_duty_original', $event)"
                                        />
                                        <span class="font-sm ml-3 min-width-70">{{ stockOrderCurrencyCode + '/' + uofm }}</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.freight') + ' (OC)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :rules="[...validationRules.required, ...validationRules.amount, ...validationRules.minimumZero]"
                                            :value="Stock__freight_cost_original"
                                            autocomplete="off"
                                            class="force-text-left"
                                            clearable
                                            dense
                                            hide-details="auto"
                                            solo
                                            @change="updateCostField('Stock__freight_cost_original', $event)"
                                        />
                                        <span class="font-sm ml-3 min-width-70">{{ stockOrderCurrencyCode + '/' + uofm }}</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.warehouseCost') + ' (OC)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :rules="[...validationRules.required, ...validationRules.amount, ...validationRules.minimumZero]"
                                            :value="Stock__wh_cost_original"
                                            autocomplete="off"
                                            class="force-text-left"
                                            clearable
                                            dense
                                            hide-details="auto"
                                            solo
                                            @change="updateCostField('Stock__wh_cost_original', $event)"
                                        />
                                        <span class="font-sm ml-3 min-width-70">{{ stockOrderCurrencyCode + '/' + uofm }}</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row pb-3>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.landedWarehouseCost') + ' (OC)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :value="Stock__landed_wh_cost_original"
                                            autocomplete="off"
                                            class="force-text-left bg-lightgrey"
                                            dense
                                            hide-details="auto"
                                            readonly
                                            solo
                                        />
                                        <span class="font-sm ml-3 min-width-70">{{ stockOrderCurrencyCode + '/' + uofm }}</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <hr>
                        <v-layout row pt-2>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.exchangeRate') + ' (Payment Date)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :value="Stock__ex_rate_stockorder"
                                            autocomplete="off"
                                            class="force-text-left bg-lightgrey"
                                            dense
                                            hide-details="auto"
                                            readonly
                                            solo
                                        />
                                        <span class="font-sm ml-3 min-width-70">{{ localStockCurrencyCode + ':' + stockOrderCurrencyCode }}</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.gradingAdjustedCost') + ' (LC)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :rules="[...validationRules.required, ...validationRules.amount, ...validationRules.minimumZero]"
                                            :value="Stock__buying_price_local"
                                            autocomplete="off"
                                            class="force-text-left"
                                            clearable
                                            dense
                                            hide-details="auto"
                                            solo
                                            @change="updateCostField('Stock__buying_price_local', $event)"
                                        />
                                        <span class="font-sm ml-3 min-width-70">{{ stockOrderCurrencyCode + '/' + uofm }}</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row pb-3>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.landedWarehouseCost') + ' (LC)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :value="Stock__landed_wh_cost_local"
                                            autocomplete="off"
                                            class="force-text-left bg-lightgrey"
                                            dense
                                            hide-details="auto"
                                            readonly
                                            solo
                                        />
                                        <span class="font-sm ml-3 min-width-70">{{ localStockCurrencyCode + '/' + uofm }}</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <hr>
                        <v-layout row pt-2>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.bookValue') + ' %' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                            :value="Stock__book_value_pct"
                                            autocomplete="off"
                                            class="force-text-left"
                                            dense
                                            hide-details="auto"
                                            solo
                                            @change="updateCostField('Stock__book_value_pct', $event)"
                                        />
                                        <span class="font-sm ml-3 min-width-70">%</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.bookValue')  + ' (OC)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                            :value="Stock__book_value_original"
                                            autocomplete="off"
                                            class="force-text-left bg-lightgrey"
                                            dense
                                            hide-details="auto"
                                            readonly
                                            solo
                                        />
                                        <span class="font-sm ml-3 min-width-70">{{ stockOrderCurrencyCode + '/' + uofm }}</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row pb-3>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.bookValue') + ' (LC)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                            :value="Stock__book_value_local"
                                            autocomplete="off"
                                            class="force-text-left bg-lightgrey"
                                            dense
                                            hide-details="auto"
                                            readonly
                                            solo
                                        />
                                        <span class="font-sm ml-3 min-width-70">{{ localStockCurrencyCode + '/' + uofm }}</span>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <hr>
                        <v-layout row pt-2>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.adjustedValue') + ' %' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                            :value="Stock__adjusted_value_pct"
                                            autocomplete="off"
                                            class="force-text-left"
                                            dense
                                            hide-details="auto"
                                            solo
                                            @change="updateCostField('Stock__adjusted_value_pct', $event)"
                                        />
                                        <div class="font-sm ml-3 min-width-70">%</div>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.adjustedValue')  + ' (OC)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                            :value="Stock__adjusted_value_original"
                                            autocomplete="off"
                                            class="force-text-left bg-lightgrey"
                                            dense
                                            hide-details="auto"
                                            readonly
                                            solo
                                        />
                                        <div class="font-sm ml-3 min-width-70">{{ stockOrderCurrencyCode + '/' + uofm }}</div>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.adjustedValue') + ' (LC)' }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <div class="d-flex flex-row">
                                    <div class="d-flex flex-row align-items-center">
                                        <v-text-field
                                            :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                            :value="Stock__adjusted_value_local"
                                            autocomplete="off"
                                            class="force-text-left bg-lightgrey"
                                            dense
                                            hide-details="auto"
                                            readonly
                                            solo
                                        />
                                        <div class="font-sm ml-3 min-width-70">{{ localStockCurrencyCode + '/' + uofm }}</div>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                    </template>
                </v-form>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                    :loading="loading.save"
                    color="info"
                    small
                    @click="saveBundles()"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {api} from "Api";
import {Decimal} from 'decimal.js'

export default {
    name: "MassUpdateBundleCost",
    props: ['bundles','dialog','type'],
    data() {
        return {
            dialogs: {
                error: false,
                error_message: ""
            },
            loading: {
                values: false,
                save: false
            },
            edit_dialog: false,

            Stock__buying_price_original: null,
            Stock__grading_adjusted_cost_original: null,
            Stock__freight_cost_original: null,
            Stock__import_duty_original: null,
            Stock__wh_cost_original: null,
            Stock__landed_wh_cost_original: null,

            Stock__ex_rate_stockorder: null,
            Stock__buying_price_local: null,
            Stock__grading_adjusted_cost_local: null,
            Stock__landed_wh_cost_local: null,


            Stock__freight_cost_local: null,
            Stock__import_duty_local: null,
            Stock__wh_cost_local: null,

            Stock__current_buying_price_original: null,
            Stock__current_grading_adjusted_cost_original: null,
            Stock__current_ex_rate: null,
            Stock__current_buying_price_local: null,
            Stock__current_grading_adjusted_cost_local: null,

            Stock__book_value_pct: 100,
            Stock__book_value_original: null,
            Stock__book_value_local: null,
            Stock__adjusted_value_pct: 100,
            Stock__adjusted_value_original: null,
            Stock__adjusted_value_local: null,

            localStockCurrencyCode: 'RM',
            stockOrderCurrencyCode: 'USD',
            uofm: 'M3',
            importDutyRate: 5,
            warehouseRate: 5,
            validForm: false
        }
    },
    computed: {
        ...mapGetters([
            'validationRules'
        ]),
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value === false) {
                    this.$emit('dialog-closed')
                }
            }
        },
    },
    methods: {
        ...mapActions('stock',{
            updateBundlesCost: 'updateBundlesCost'
        }),
        loadBundleValues () {
            this.loading.values = true

            let bundleIds = []

            this.bundles.forEach(b => {
                if(b.Stock.id != null){
                    bundleIds.push(b.Stock.id)
                }
            })

            api
                .get("/stocks/cost-values", {
                    params: {
                        stock_ids: bundleIds,
                    }
                })
                .then(response => {
                    if(response.data.status === 'success') {
                        const data = response.data.data
                        this.Stock__buying_price_original = data.buying_price_original
                        this.Stock__grading_adjusted_cost_original = data.grading_adjusted_cost_original
                        this.Stock__import_duty_original = data.import_duty_original
                        this.Stock__freight_cost_original = data.freight_cost_original
                        this.Stock__wh_cost_original = data.wh_cost_original
                        this.Stock__landed_wh_cost_original = data.landed_wh_cost_original

                        this.Stock__ex_rate_stockorder = data.ex_rate_stockorder
                        this.Stock__buying_price_local = data.buying_price_local
                        this.Stock__grading_adjusted_cost_local = data.grading_adjusted_cost_local
                        this.Stock__landed_wh_cost_local = data.landed_wh_cost_local

                        this.Stock__import_duty_local = data.import_duty_local
                        this.Stock__freight_cost_local = data.freight_cost_local
                        this.Stock__wh_cost_local = data.wh_cost_local

                        this.Stock__current_buying_price_original = data.current_buying_price_original
                        this.Stock__current_grading_adjusted_cost_original = data.current_grading_adjusted_cost_original
                        this.Stock__current_ex_rate = data.current_ex_rate
                        this.Stock__current_buying_price_local = data.current_buying_price_local
                        this.Stock__current_grading_adjusted_cost_local = data.current_grading_adjusted_cost_local

                        this.Stock__book_value_pct = data.book_value_pct
                        this.Stock__book_value_original = data.book_value_original
                        this.Stock__book_value_local = data.book_value_local
                        this.Stock__adjusted_value_pct = data.adjusted_value_pct
                        this.Stock__adjusted_value_original = data.adjusted_value_original
                        this.Stock__adjusted_value_local = data.adjusted_value_local

                        this.localStockCurrencyCode = data.local_stock_currency_code
                        this.stockOrderCurrencyCode = data.stock_order_currency_code
                        this.uofm = data.uofm
                        this.warehouseRate = data.warehouseRate
                        this.importDutyRate = data.importDutyRate
                    } else {
                        //do nothing?
                    }
                    this.loading.values = false
                })
                .catch(() => {
                    this.loading.values = false
                })
        },
        saveBundles () {
            if(this.$refs.massBundleForm.validate()) {
                this.loading.save = true
                let payload = {
                    bundles: this.bundles,
                    fields: {
                        buying_price_original: this.Stock__buying_price_original,
                        grading_adjusted_cost_original: this.Stock__grading_adjusted_cost_original,
                        import_duty_original: this.Stock__import_duty_original,
                        freight_cost_original: this.Stock__freight_cost_original,
                        wh_cost_original: this.Stock__wh_cost_original,
                        landed_wh_cost_original: this.Stock__landed_wh_cost_original,
                        ex_rate_stockorder: this.Stock__ex_rate_stockorder,
                        buying_price_local: this.Stock__buying_price_local,
                        grading_adjusted_cost_local: this.Stock__grading_adjusted_cost_local,
                        landed_wh_cost_local: this.Stock__landed_wh_cost_local,
                        import_duty_local: this.Stock__import_duty_local,
                        freight_cost_local: this.Stock__freight_cost_local,
                        wh_cost_local: this.Stock__wh_cost_local,
                        current_buying_price_original: this.Stock__current_buying_price_original,
                        current_grading_adjusted_cost_original: this.Stock__current_grading_adjusted_cost_original,
                        current_ex_rate: this.Stock__current_ex_rate,
                        current_buying_price_local: this.Stock__current_buying_price_local,
                        current_grading_adjusted_cost_local: this.Stock__current_grading_adjusted_cost_local,
                        book_value_pct: this.Stock__book_value_pct,
                        book_value_original: this.Stock__book_value_original,
                        book_value_local: this.Stock__book_value_local,
                        adjusted_value_pct: this.Stock__adjusted_value_pct,
                        adjusted_value_original: this.Stock__adjusted_value_original,
                        adjusted_value_local: this.Stock__adjusted_value_local,
                        local_stock_currency_code: this.localStockCurrencyCode,
                        stock_order_currency_code: this.stockOrderCurrencyCode,
                        uofm: this.uofm
                    }
                }
                this.updateBundlesCost(payload)
                    .then((response) => {
                        if (response.status === 'success') {
                            this.$toast.success(this.$t('message.successes.bundlesUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.save = false
                            this.$emit('update-done')
                        } else {
                            this.$toast.error(this.$t('message.errors.bundlesNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        }
                    }).catch(() => {
                        this.$toast.error(this.$t('message.errors.bundlesNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.save = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.bundlesNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                    icon: 'error_outline'
                    }
                )
            }
        },
        resetForm () {
            this.Stock__buying_price_original =  null
            this.Stock__grading_adjusted_cost_original = null
            this.Stock__import_duty_original =  null
            this.Stock__freight_cost_original =  null
            this.Stock__wh_cost_original =  null
            this.Stock__landed_wh_cost_original =  null

            this.Stock__ex_rate_stockorder =  null
            this.Stock__buying_price_local =  null
            this.Stock__grading_adjusted_cost_local = null
            this.Stock__landed_wh_cost_local =  null
            this.Stock__import_duty_local =  null
            this.Stock__freight_cost_local =  null
            this.Stock__wh_cost_local =  null

            this.Stock__current_buying_price_original =  null
            this.Stock__current_grading_adjusted_cost_original = null
            this.Stock__current_ex_rate =  null
            this.Stock__current_buying_price_local =  null
            this.Stock__current_grading_adjusted_cost_local = null

            this.Stock__book_value_pct =  100
            this.Stock__book_value_original =  null
            this.Stock__book_value_local =  null

            this.Stock__adjusted_value_pct =  100
            this.Stock__adjusted_value_original =  null
            this.Stock__adjusted_value_local =  null

            this.localStockCurrencyCode =  'RM'
            this.stockOrderCurrencyCode =  'USD'
            this.uofm =  'M3'
            this.importDutyRate = 5
            this.warehouseRate = 5
        },
        updateCostField (field, value) {
            this[field] = value;

            this.$nextTick(() => {
                if(this.type === 'NSV') {

                    const importDutyRate = new Decimal(this.importDutyRate + '');
                    const warehouseRate = new Decimal(this.warehouseRate + '');

                    const buyingPriceOriginal = new Decimal(this.Stock__buying_price_original + '');

                    const freightCostOriginal = new Decimal(this.Stock__freight_cost_original + '');

                    const warehouseCostOriginal = buyingPriceOriginal.times(warehouseRate);
                    if (field === 'Stock__buying_price_original') {
                        this.Stock__wh_cost_original = warehouseCostOriginal.toDP(2)
                    }

                    const importDutyOriginal = buyingPriceOriginal.times(importDutyRate);
                    if (field === 'Stock__buying_price_original') {
                        this.Stock__import_duty_original = importDutyOriginal.toDP(2)
                    }

                    //auto calculate
                    const landedWarehouseCostOriginal = buyingPriceOriginal.plus(importDutyOriginal).plus(freightCostOriginal).plus(warehouseCostOriginal);
                    this.Stock__landed_wh_cost_original = landedWarehouseCostOriginal.toDP(2)

                    const exRateStockOrder = new Decimal(this.Stock__ex_rate_stockorder + '');
                    const buyingPriceLocal = buyingPriceOriginal.times(exRateStockOrder)
                    const landedWarehouseCostLocal = landedWarehouseCostOriginal.times(exRateStockOrder)
                    this.Stock__buying_price_local = buyingPriceLocal.toDP(2)
                    this.Stock__landed_wh_cost_local = landedWarehouseCostLocal.toDP(2)

                    const currentBuyingPriceOriginal = new Decimal(this.Stock__current_buying_price_original + '');
                    const currentExRate = new Decimal(this.Stock__current_ex_rate + '');

                    this.Stock__current_ex_rate = currentExRate //to convert field value to string

                    const currentBuyingPriceLocal = currentBuyingPriceOriginal.times(currentExRate);
                    this.Stock__current_buying_price_local = currentBuyingPriceLocal.toDP(2)

                    const oneHundred = new Decimal('100');

                    const bookValuePct = new Decimal(this.Stock__book_value_pct + '').div('100');
                    const bookValueOriginal = landedWarehouseCostOriginal.times(bookValuePct);
                    const bookValueLocal = landedWarehouseCostLocal.times(bookValuePct);
                    this.Stock__book_value_original = bookValueOriginal.toDP(2);
                    this.Stock__book_value_local = bookValueLocal.toDP(2)

                    const adjustedValuePct = new Decimal(this.Stock__adjusted_value_pct + '').div('100');
                    const adjustedValueOriginal = landedWarehouseCostOriginal.times(adjustedValuePct);
                    const adjustedValueLocal = landedWarehouseCostLocal.times(adjustedValuePct);
                    this.Stock__adjusted_value_original = adjustedValueOriginal.toDP(2)
                    this.Stock__adjusted_value_local = adjustedValueLocal.toDP(2)

                } else if(this.type === 'SV') {
                    const importDutyRate = new Decimal(this.importDutyRate + '');
                    const warehouseRate = new Decimal(this.warehouseRate + '');

                    const gradingAdjustedCostOriginal = new Decimal(this.Stock__grading_adjusted_cost_original + '');

                    const freightCostOriginal = new Decimal(this.Stock__freight_cost_original + '');

                    const warehouseCostOriginal = gradingAdjustedCostOriginal.times(warehouseRate);
                    if (field === 'Stock__grading_adjusted_cost_original') {
                        this.Stock__wh_cost_original = warehouseCostOriginal.toDP(2)
                    }

                    const importDutyOriginal = gradingAdjustedCostOriginal.times(importDutyRate);
                    if (field === 'Stock__grading_adjusted_cost_original') {
                        this.Stock__import_duty_original = importDutyOriginal.toDP(2)
                    }

                    //auto calculate
                    const landedWarehouseCostOriginal = gradingAdjustedCostOriginal.plus(importDutyOriginal).plus(freightCostOriginal).plus(warehouseCostOriginal);
                    this.Stock__landed_wh_cost_original = landedWarehouseCostOriginal.toDP(2)

                    const exRateStockOrder = new Decimal(this.Stock__ex_rate_stockorder + '');
                    const gradingAdjustedCostLocal = gradingAdjustedCostOriginal.times(exRateStockOrder)
                    const landedWarehouseCostLocal = landedWarehouseCostOriginal.times(exRateStockOrder)
                    this.Stock__buying_price_local = gradingAdjustedCostLocal.toDP(2)
                    this.Stock__landed_wh_cost_local = landedWarehouseCostLocal.toDP(2)

                    const currentGradingAdjustedCostOriginal = new Decimal(this.Stock__current_grading_adjusted_cost_original + '');
                    const currentExRate = new Decimal(this.Stock__current_ex_rate + '');

                    this.Stock__current_ex_rate = currentExRate //to convert field value to string

                    const currentGradingAdjustedCostLocal = currentGradingAdjustedCostOriginal.times(currentExRate);
                    this.Stock__current_grading_adjusted_cost_local = currentGradingAdjustedCostLocal.toDP(2)

                    const oneHundred = new Decimal('100');

                    const bookValuePct = new Decimal(this.Stock__book_value_pct + '').div('100');
                    const bookValueOriginal = landedWarehouseCostOriginal.times(bookValuePct);
                    const bookValueLocal = landedWarehouseCostLocal.times(bookValuePct);
                    this.Stock__book_value_original = bookValueOriginal.toDP(2);
                    this.Stock__book_value_local = bookValueLocal.toDP(2)

                    const adjustedValuePct = new Decimal(this.Stock__adjusted_value_pct + '').div('100');
                    const adjustedValueOriginal = landedWarehouseCostOriginal.times(adjustedValuePct);
                    const adjustedValueLocal = landedWarehouseCostLocal.times(adjustedValuePct);
                    this.Stock__adjusted_value_original = adjustedValueOriginal.toDP(2)
                    this.Stock__adjusted_value_local = adjustedValueLocal.toDP(2)
                }
            })
        }
    },
    watch: {
        dialog(value) {
            if(value === false){
                this.resetForm()
            } else {
                this.loadBundleValues()
            }
            this.edit_dialog = value
        }
    },
    created () {

    },
    mounted() {

    }
}
</script>

<style scoped>
.min-width-70 {
    min-width: 70px !important;
}
.bg-lightgrey {
    background-color: #EEEEEE;
}
</style>